import { useState, useCallback, useMemo } from "react"
import "./Boms.css"
import useFetch from "../../../../hooks/useFetch"
import { AgGridReact } from "ag-grid-react" // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css" // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css" // Optional Theme applied to the Data Grid
import Select from "react-select"
import ErrorMessage from "../../../layout/ErrorMessage/ErrorMessage"
import LoadingMessage from "../../../layout/LoadingMessage/LoadingMessage"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashCan } from "@fortawesome/free-solid-svg-icons"

import axios from "axios"
import ButtonBar from "../../../layout/ButtonBar/ButtonBar"
import { formatDate } from "../../../../utils/format"

const Boms = ({ material, reloadMaterial }) => {
	const materialId = material.material_id
	const {
		error,
		isPending,
		data: boms,
		reloadData: reloadBoms,
	} = useFetch("/api/bom?material_id=" + materialId)

	const [showNewBomForm, setShowNewBomForm] = useState(false)
	// retrieve the selected rows from the grid
	const [selectedBoms, setSelectedBoms] = useState([])

	const toggleNewBomForm = () => {
		setShowNewBomForm(!showNewBomForm)
	}

	const handleSetDefaultBom = () => {
		let bomIds = selectedBoms.map(({ bom_id }) => bom_id)
		if (bomIds.length !== 1)
			return alert("Only one bom can be set as default.")
		const newDefaultBomId = bomIds[0]
		axios
			.put("/api/material/defaultBom", {
				materialId: materialId,
				bomId: newDefaultBomId,
			})
			.then(res => {
				console.log(res)
				alert("Success")
				reloadMaterial()
			})
			.catch(err => {
				console.log(err)
				alert(err.message)
			})
	}

	return (
		<div id='Boms'>
			<h3>Default Bom</h3>
			<p>
				{material.default_bom_id
					? `The current default BOM is ${material.default_bom_id}.`
					: "No default bom has been set."}
			</p>
			<h3>BOMs</h3>
			<p>
				A Bill of Materials (BOM) lists the materials needed to assemble
				a product. You can't edit or delete a BOM, but you can create
				new ones for future use or archive old ones that are no longer
				needed.
			</p>
			<ButtonBar>
				<button onClick={toggleNewBomForm}>Create new BOM</button>
				<button
					className='Button'
					onClick={handleSetDefaultBom}
					disabled={!(selectedBoms.length === 1)}>
					Set as default
				</button>
			</ButtonBar>
			{showNewBomForm && (
				<NewBomForm
					toggle={toggleNewBomForm}
					materialId={materialId}
					reloadBoms={reloadBoms}
				/>
			)}
			{error && <ErrorMessage message={error} />}
			{isPending && <LoadingMessage />}
			{boms && <BomList boms={boms} setSelectedBoms={setSelectedBoms} />}
		</div>
	)
}

const BomList = ({ boms, setSelectedBoms }) => {
	// Row Data: The data to be displayed.
	const rowData = boms
	const [gridApi, setGridApi] = useState(null)

	const onGridReady = useCallback(params => {
		setGridApi(params.api)
	}, [])

	// Apply settings across all columns
	const defaultColDef = useMemo(() => ({
		filter: true,
	}))

	// Column Definitions: Defines the columns to be displayed.
	const [colDefs, setColDefs] = useState([
		{
			headerName: "Id",
			field: "bom_id",
			checkboxSelection: true,
			headerCheckboxSelection: true,
		},
		{ headerName: "Name", field: "name" },
		{
			headerName: "Created at",
			field: "created_at",
			cellRenderer: ({ value }) => formatDate(value),
		},
	])

	const onSelectionChanged = () => {
		const selectedData = gridApi.getSelectedRows()
		setSelectedBoms(selectedData)
	}

	const rowSelection = "multiple"

	return (
		<div className='ag-theme-quartz' style={{ height: 300 }}>
			<AgGridReact
				rowData={rowData}
				columnDefs={colDefs}
				pagination={true}
				defaultColDef={defaultColDef}
				rowSelection={rowSelection}
				onSelectionChanged={onSelectionChanged}
				onGridReady={onGridReady}
			/>
		</div>
	)
}

const NewBomForm = ({ toggle, materialId, reloadBoms }) => {
	const [bomName, setBomName] = useState("BOM for Material " + materialId)
	const [inputs, setInputs] = useState([{ selected_option: null, qty: 1 }])

	// get a list of materials for the dropdown inputs
	const { error, isPending, data: materials } = useFetch("/api/material")
	let options = []
	if (materials) {
		options = materials.map(m => {
			const label = "[" + m.sku + "] " + m.name
			return { value: m.material_id, label: label }
		})
	}

	const handleNewLineClick = e => {
		e.preventDefault()
		setInputs([...inputs, { selected_option: null, qty: 1 }])
	}

	const handleMaterialInputChange = (i, o) => {
		const inputsCopy = [...inputs]
		inputsCopy[i].selected_option = o
		setInputs(inputsCopy)
	}

	const handleQtyInputChange = (i, e) => {
		e.preventDefault()
		const inputsCopy = [...inputs]
		inputsCopy[i].qty = e.target.value
		setInputs(inputsCopy)
	}

	const handleDeleteLineClick = (i, e) => {
		e.preventDefault()
		const inputsCopy = [...inputs]
		inputsCopy.splice(i, 1)
		setInputs(inputsCopy)
	}

	const handleSubmit = e => {
		e.preventDefault()
		const materialIdInt = parseInt(materialId)
		let data = {
			bom_name: bomName,
			material_id: materialIdInt,
			materials: [],
		}
		for (const i in inputs) {
			const input = inputs[i]
			const materialIdInt = parseInt(input.selected_option.value)
			const materialQtyInt = parseInt(input.qty)
			data.materials.push({
				material_id: materialIdInt,
				qty: materialQtyInt,
			})
		}
		axios
			.post("/api/bom", data)
			.then(res => {
				reloadBoms() // reload the BOMs list
				toggle() // hide the form
			})
			.catch(err => {
				console.log(err)
				alert(err.message)
			})
	}

	if (error) return <ErrorMessage message={error} />
	if (isPending) return <LoadingMessage />
	return (
		<div id='NewBomForm'>
			<div className='content'>
				<label>BOM Name</label>
				<input
					type='text'
					value={bomName}
					onChange={e => setBomName(e.target.value)}
					placeholder='Enter a name for this BOM. (i.e. New supplier v1.2) '
				/>
				<div
					className='inputGroup'
					style={{
						borderBottom: "1px solid #000",
						paddingBottom: "5px",
						marginBottom: "5px",
					}}>
					<div className='ig-mtl'>
						<span>Material</span>
					</div>
					<div className='ig-qty'>
						<span>QTY</span>
					</div>
				</div>
				{inputs.map((data, i) => {
					return (
						<div key={i} className='inputGroup'>
							<div className='ig-mtl'>
								<Select
									options={options}
									value={data.selected_option}
									onChange={o =>
										handleMaterialInputChange(i, o)
									}
								/>
							</div>
							<div className='ig-qty'>
								<input
									type='number'
									value={data.qty}
									onChange={e => handleQtyInputChange(i, e)}
								/>
							</div>
							<div className='ig-del'>
								<button
									onClick={e => handleDeleteLineClick(i, e)}>
									<FontAwesomeIcon icon={faTrashCan} />
								</button>
							</div>
						</div>
					)
				})}
				<div className='buttonContainer right space'>
					<button onClick={handleNewLineClick}> + New line</button>
				</div>
				<div className='buttonContainer right space'>
					<input type='submit' onClick={handleSubmit} />
				</div>
				<div className='buttonContainer right space'>
					<button className='negative' onClick={toggle}>
						Cancel
					</button>
				</div>
			</div>
		</div>
	)
}

export default Boms
