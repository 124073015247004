import { useState, useEffect, useCallback } from 'react'

const useFetch = (url, params = {}) => {
	const paramsString = JSON.stringify(params) // allows params to be used as a dependency
	const [state, setState] = useState({
		data: null,
		isPending: true,
		error: null,
	})
	const reloadData = () => {
		setState({ data: state.data, isPending: true, error: null }) // keep old data until new data has arrived
		loadData()
	}
	const loadData = useCallback(() => {
		const searchParams = new URLSearchParams(params).toString() // converts optional object into search params and appends to url
		console.log(searchParams)
		var queryString = url + (searchParams ? '?' + searchParams : '')
		console.log('Fetched ' + queryString)
		fetch(queryString)
			.then(res => {
				if (res.ok) return res.json()
				console.log(res)
				return res.json().then(data => {
					throw new Error(data.error || data.message || res.statusText)
				})
			})
			.then(data => {
				setState({ data: data, isPending: false, error: null })
			})
			.catch(error => {
				console.log(error)
				setState({ data: null, isPending: false, error: error.message })
			})
	}, [url, paramsString])
	useEffect(() => {
		// reset values so components re-render properly
		setState({ data: null, isPending: true, error: null })
		loadData()
	}, [url, paramsString])
	return { ...state, reloadData }
}

export default useFetch
