import PageTitle from "../../layout/PageTitle"
import { AgGridReact } from "ag-grid-react" // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css" // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css" // Optional Theme applied to the Data Grid
import { useMemo, useState, useCallback, useEffect } from "react"
import { ButtonBar } from "../../layout/ButtonBar/ButtonBar"
import PageContent from "../../layout/PageContent"
import useFetch from "../../../hooks/useFetch"
import axios from "axios"
import "./Customers.css"
import LoadingMessage from "../../layout/LoadingMessage/LoadingMessage"
import ErrorMessage from "../../layout/ErrorMessage/ErrorMessage"

const Customers = () => {
	const {
		error,
		isPending,
		data: customers,
		reloadData: reloadCustomerTable,
	} = useFetch("/api/customer")

	// retrieve the selected rows from the grid
	const [selectedCustomers, setSelectedCustomers] = useState([])
	// boolean for if customers are selected
	const customersAreSelected = selectedCustomers.length > 0
	// handle when the delete selected customers button is pressed
	const handleArchiveSelectedCustomers = () => {
		// err here
		let customerIds = selectedCustomers.map(({ customer_id }) => customer_id)
		axios
			.post("/api/customer/archive", customerIds)
			.then(res => {
				console.log(res)
			})
			.catch(err => {
				console.log(err)
				alert(err.message)
			})
	}

	// toggle new customer form
	const [showNewCustomerForm, setShowNewCustomerForm] = useState(false)
	const toggleNewCustomerForm = () => {
		setShowNewCustomerForm(!showNewCustomerForm)
	}

	return (
		<div id='Customers'>
			<PageTitle>Customers</PageTitle>
			<PageContent>
				<ButtonBar>
					<button className='Button' onClick={toggleNewCustomerForm}>
						New Customer
					</button>
					<button
						className='Button'
						onClick={handleArchiveSelectedCustomers}
						disabled={!customersAreSelected}>
						Archive Customer(s)
					</button>
				</ButtonBar>
				{showNewCustomerForm && (
					<NewCustomerForm reloadCustomerTable={reloadCustomerTable} />
				)}
				{error && <ErrorMessage message={error} />}
				{isPending && <LoadingMessage />}
				{customers && (
					<CustomersTable
						data={customers}
						setSelectedCustomers={setSelectedCustomers}
					/>
				)}
			</PageContent>
		</div>
	)
}

const CustomersTable = props => {
	// Row Data: The data to be displayed.
	const rowData = props.data
	console.log(props.data)
	const [gridApi, setGridApi] = useState(null)

	const onGridReady = useCallback(params => {
		setGridApi(params.api)
	}, [])

	// Apply settings across all columns
	const defaultColDef = useMemo(() => ({
		filter: true,
	}))

	// Column Definitions: Defines the columns to be displayed.
	const [colDefs, setColDefs] = useState([
		{
			headerName: "Id",
			field: "customerId",
			checkboxSelection: true,
			headerCheckboxSelection: true,
		},
		{ headerName: "Display Name", field: "display_name" },
	])

	const onSelectionChanged = () => {
		const selectedData = gridApi.getSelectedRows()
		props.setSelectedCustomers(selectedData)
	}

	const rowSelection = "multiple"

	return (
		<div className='ag-theme-quartz' style={{ height: 400 }}>
			<AgGridReact
				rowData={rowData}
				columnDefs={colDefs}
				pagination={true}
				defaultColDef={defaultColDef}
				rowSelection={rowSelection}
				onSelectionChanged={onSelectionChanged}
				onGridReady={onGridReady}
			/>
		</div>
	)
}

const NewCustomerForm = props => {
	const [inputs, setInputs] = useState({})

	const handleChange = event => {
		const name = event.target.name
		const value = event.target.value
		setInputs(values => ({ ...values, [name]: value }))
	}

	const handleSubmit = async event => {
		event.preventDefault()
		axios
			.post("/api/customer", inputs)
			.then(res => {
				console.log(res)
				alert("New customer created.")
				props.reloadCustomerTable()
			})
			.catch(err => {
				console.log(err)
				alert(err.message)
			})
	}
	return (
		<div id='NewCustomerForm'>
			<form onSubmit={handleSubmit}>
				<label htmlFor='displayName'>Display Name</label>
				<input
					name='displayName'
					type='text'
					value={inputs.displayName || ""}
					onChange={handleChange}
				/>
				<input type='submit' />
			</form>
		</div>
	)
}

export default Customers
