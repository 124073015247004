import UnitsAssembledMonthBarChart from "./charts/UnitsAssembledMonthBarChart"
import TestChart from "./charts/UnitsAssembledMonthBarChart"
import Collapsible from "./layout/Collapsible/Collapsible"
import PageContent from "./layout/PageContent"
import PageTitle from "./layout/PageTitle"

function Homepage() {
	return (
		<>
			<PageTitle>Dashboard</PageTitle>
			<PageContent>
				<div className='home-layout'>
					<div className='hl-left'>
						<Collapsible title={'Announcements'}>

						</Collapsible>
						<Collapsible title={'Assembly'}>
							<UnitsAssembledMonthBarChart />
						</Collapsible>
						<Collapsible title={'Testing'}>123</Collapsible>
					</div>
					<div className='hl-right'>
						<Collapsible title={'Quick Links'}>
						</Collapsible>
						<Collapsible title={'Recently Assembled'}>
							<ul>
								<li>Dictumst mattis.</li>
								<li>Volutpat tellus!</li>
								<li>Hendrerit phasellus.</li>
								<li>Sem blandit ullamcorper.</li>
								<li>Class fringilla nec?</li>
							</ul>
						</Collapsible>
						<Collapsible title={''}>
							<ul>
								<li>Dictumst mattis.</li>
								<li>Volutpat tellus!</li>
								<li>Hendrerit phasellus.</li>
								<li>Sem blandit ullamcorper.</li>
								<li>Class fringilla nec?</li>
							</ul>
						</Collapsible>
					</div>
				</div>
			</PageContent>
		</>
	)
}

export default Homepage
