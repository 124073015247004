import PageTitle from "../../layout/PageTitle"
import { AgGridReact } from "ag-grid-react" // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css" // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css" // Optional Theme applied to the Data Grid
import { useMemo, useState, useCallback, useEffect } from "react"
import { ButtonBar } from "../../layout/ButtonBar/ButtonBar"
import PageContent from "../../layout/PageContent"
import useFetch from "../../../hooks/useFetch"
import axios from "axios"
import "./Vendors.css"
import LoadingMessage from "../../layout/LoadingMessage/LoadingMessage"
import ErrorMessage from "../../layout/ErrorMessage/ErrorMessage"

const Vendors = () => {
	const {
		error,
		isPending,
		data: vendors,
		reloadData: reloadVendorTable,
	} = useFetch("/api/vendor")

	// retrieve the selected rows from the grid
	const [selectedVendors, setSelectedVendors] = useState([])
	// boolean for if vendors are selected
	const vendorsAreSelected = selectedVendors.length > 0
	// handle when the delete selected vendors button is pressed
	const handleArchiveSelectedVendors = () => {
		// err here
		let vendorIds = selectedVendors.map(({ vendor_id }) => vendor_id)
		axios
			.post("/api/vendor/archive", vendorIds)
			.then(res => {
				console.log(res)
			})
			.catch(err => {
				console.log(err)
				alert(err.message)
			})
	}

	// toggle new vendor form
	const [showNewVendorForm, setShowNewVendorForm] = useState(false)
	const toggleNewVendorForm = () => {
		setShowNewVendorForm(!showNewVendorForm)
	}

	return (
		<div id='Vendors'>
			<PageTitle>Vendors</PageTitle>
			<PageContent>
				<ButtonBar>
					<button className='Button' onClick={toggleNewVendorForm}>
						New Vendor
					</button>
					<button
						className='Button'
						onClick={handleArchiveSelectedVendors}
						disabled={!vendorsAreSelected}>
						Archive Vendor(s)
					</button>
				</ButtonBar>
				{showNewVendorForm && (
					<NewVendorForm reloadVendorTable={reloadVendorTable} />
				)}
				{error && <ErrorMessage message={error} />}
				{isPending && <LoadingMessage />}
				{vendors && (
					<VendorsTable
						data={vendors}
						setSelectedVendors={setSelectedVendors}
					/>
				)}
			</PageContent>
		</div>
	)
}

const VendorsTable = props => {
	// Row Data: The data to be displayed.
	const rowData = props.data
	console.log(props.data)
	const [gridApi, setGridApi] = useState(null)

	const onGridReady = useCallback(params => {
		setGridApi(params.api)
	}, [])

	// Apply settings across all columns
	const defaultColDef = useMemo(() => ({
		filter: true,
	}))

	// Column Definitions: Defines the columns to be displayed.
	const [colDefs, setColDefs] = useState([
		{
			headerName: "Id",
			field: "vendorId",
			checkboxSelection: true,
			headerCheckboxSelection: true,
		},
		{ headerName: "Display Name", field: "display_name" },
	])

	const onSelectionChanged = () => {
		const selectedData = gridApi.getSelectedRows()
		props.setSelectedVendors(selectedData)
	}

	const rowSelection = "multiple"

	return (
		<div className='ag-theme-quartz' style={{ height: 400 }}>
			<AgGridReact
				rowData={rowData}
				columnDefs={colDefs}
				pagination={true}
				defaultColDef={defaultColDef}
				rowSelection={rowSelection}
				onSelectionChanged={onSelectionChanged}
				onGridReady={onGridReady}
			/>
		</div>
	)
}

const NewVendorForm = props => {
	const [inputs, setInputs] = useState({})

	const handleChange = event => {
		const name = event.target.name
		const value = event.target.value
		setInputs(values => ({ ...values, [name]: value }))
	}

	const handleSubmit = async event => {
		event.preventDefault()
		axios
			.post("/api/vendor", inputs)
			.then(res => {
				console.log(res)
				alert("New vendor created.")
				props.reloadVendorTable()
			})
			.catch(err => {
				console.log(err)
				alert(err.message)
			})
	}
	return (
		<div id='NewVendorForm'>
			<form onSubmit={handleSubmit}>
				<label htmlFor='displayName'>Display Name</label>
				<input
					name='displayName'
					type='text'
					value={inputs.displayName || ""}
					onChange={handleChange}
				/>
				<input type='submit' />
			</form>
		</div>
	)
}

export default Vendors
