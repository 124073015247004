// Frontend Code (React Component)
import React, { useEffect, useState } from 'react'
import { AgCharts } from 'ag-charts-react'
import axios from 'axios'

const UnitsAssembledMonthBarChart = () => {
	const [options, setOptions] = useState({
		title: {
			text: 'Units Assembled for the Month',
		},
		data: [],
		series: [
			{
				type: 'bar',
				xKey: 'name',
				yKey: 'value',
				yName: 'Total Assembled Units',
			},
		],
		axes: [
			{
				type: 'category',
				position: 'bottom',
				title: { text: 'Material' },
			},
			{
				type: 'log',
				position: 'left',
				title: { text: 'Total Assembled Units' },
			},
		],
	})
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		// Fetch data from the backend API
		const fetchData = async () => {
			try {
				const response = await axios.get('/api/chart/topAssembledUnits')
				if (response.data && response.data.data) {
					const barData = response.data.data
                    console.log(response)

					setOptions(prevOptions => ({
						...prevOptions,
						data: barData,
					}))
				} else {
					setOptions(prevOptions => ({
						...prevOptions,
						data: [],
					}))
				}
			} catch (error) {
				console.error('Error fetching chart data:', error)
				setOptions(prevOptions => ({
					...prevOptions,
					data: [],
				}))
			} finally {
				setIsLoading(false)
			}
		}
		fetchData()
	}, [])

	if (isLoading) {
		return <div>Loading...</div>
	}

	return <div className='chart-container'>{options.data.length > 0 ? <AgCharts options={options} style={{height: 500}}/> : <div>No data available for the selected month.</div>}</div>
}

export default UnitsAssembledMonthBarChart
