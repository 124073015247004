import "./ButtonBar.css"

function ButtonBar(props) {
	return <div className='ButtonBar'>{props.children}</div>
}

function Button({ link, text }) {
	return (
		<a className='Button' href={link}>
			{text}
		</a>
	)
}

export default ButtonBar
export { ButtonBar, Button }
