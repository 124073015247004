import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PageContent from '../../layout/PageContent'
import PageTitle from '../../layout/PageTitle'
import './QuickAssemble.css'
import Collapsible from '../../layout/Collapsible/Collapsible'
import { useParams, useSearchParams } from 'react-router-dom'
import useFetch from '../../../hooks/useFetch'
import ErrorMessage from '../../layout/ErrorMessage/ErrorMessage'
import LoadingMessage from '../../layout/LoadingMessage/LoadingMessage'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import UserContext from '../../Context/UserContext'
import { AgGridReact } from 'ag-grid-react'
import { formatDate } from '../../../utils/format'
import placeholderImage from '../../../images/placeholder-square.jpg'

const QuickAssemble = () => {
	let { materialId } = useParams()
	let [searchParams, setSearchParams] = useSearchParams()
	const [bomId, setBomId] = useState(searchParams.get('bomId'))
	const { user } = useContext(UserContext)

	const {
		error,
		isPending,
		data: material,
		reloadData: reloadMaterial,
	} = useFetch('/api/material', {
		id: materialId,
	})
	const {
		error: errorBom,
		isPending: isPendingBom,
		data: bom,
		reloadData: reloadBom,
	} = useFetch('/api/bom', {
		bom_id: bomId,
	})
	const {
		error: errorAssemblyLog,
		isPending: isPendingAssemblyLog,
		data: assemblyLogData,
		reloadData: reloadAssemblyLog,
	} = useFetch('/api/assemble/log', {
		materialId: materialId,
	})

	const [numberInput, setNumberInput] = useState(0)

	useEffect(() => {
		if ((bomId === 'default' || bomId == null) && material?.default_bom_id) {
			setSearchParams({ bomId: material.default_bom_id })
			setBomId(material.default_bom_id)
		}
	}, [bomId, material, setSearchParams])

	const [submitStatus, setSubmitStatus] = useState('idle') // "idle, submitting, complete, error"

	useEffect(() => {
		if (submitStatus === 'complete') {
			const timeoutId = setTimeout(() => {
				setSubmitStatus('idle')
			}, 2000)
			return () => clearTimeout(timeoutId)
		}
	}, [submitStatus])

	const submitAssemble = qty => {
		if (numberInput === 0) return alert('Enter a number to assemble')
		setSubmitStatus('submitting')
		axios
			.post('/api/assemble', {
				locationId: user.activeLocation.id,
				materialId: materialId,
				bomId: bomId,
				qtyToAssemble: qty,
			})
			.then(res => {
				setNumberInput(0)
				setSubmitStatus('complete')
				reloadAssemblyLog()
			})
			.catch(err => {
				console.log(err)
				setSubmitStatus('error')
				alert(err.message)
			})
	}

	if (isPending || isPendingBom) return <LoadingMessage />
	if (errorBom) return <ErrorMessage message={"Bom Error: " + errorBom} />
	if (error) return <ErrorMessage message={error} />
	if (!(bom?.material_id === materialId)) return <ErrorMessage message={'The selected bom does not assemble the requested material.'} />

	const hostLocation = 'https://storage.googleapis.com/conflux-uploads/'
	const materialImage = material.image_location ? hostLocation + material.image_location : placeholderImage

	return (
		<div id='QuickAssemble'>
			<PageTitle>Quick Assemble</PageTitle>
			<PageContent>
				<Collapsible title={'Material Information'}>
					<img width={100} height={100} alt={material.name} src={materialImage} />
					<p>
						<b>Assembling:</b> [{material.sku}] {material.name}{' '}
						<a target='_blank' rel='noreferrer' href={'/dashboard/material/' + material?.material_id}>
							<FontAwesomeIcon icon={faUpRightFromSquare} />
						</a>
					</p>
					<p>
						<b>BomID:</b> [{bom?.bom_id}] {bom?.bom_name}
					</p>
					<p>
						<b>Location:</b> [{user.activeLocation.id}] {user.activeLocation.name}
					</p>
				</Collapsible>
				{bom && bom?.material_id === materialId && <NumberKeypad numberInput={numberInput} setNumberInput={setNumberInput} submitAssemble={submitAssemble} submitStatus={submitStatus} />}
				<Collapsible title='Assembly History'>
					{isPendingAssemblyLog && <LoadingMessage />}
					{errorAssemblyLog && <ErrorMessage message={errorAssemblyLog} />}
					{assemblyLogData && <AssemblyLog data={assemblyLogData} />}
				</Collapsible>
			</PageContent>
		</div>
	)
}

const NumberKeypad = ({ numberInput, setNumberInput, submitAssemble, submitStatus }) => {
	const handleNumberClick = e => {
		const input = e.target.value
		if (input === 'CLR') return setNumberInput(0)
		const newInput = parseInt('' + numberInput + input)
		setNumberInput(newInput)
	}

	const handleInputChange = e => {
		setNumberInput(e.target.value)
	}

	const handleAssembleSubmit = () => {
		submitAssemble(numberInput)
	}

	const handleDisassembleSubmit = () => {
		const numberToSubmit = numberInput * -1
		setNumberInput(numberToSubmit)
		submitAssemble(numberToSubmit)
	}

	return (
		<div className={'NumberKeypad status-' + submitStatus}>
			<input type='number' value={numberInput} onChange={handleInputChange} />
			<div className='number-buttons'>
				{[1, 2, 3, 4, 5, 6, 7, 8, 9, 'CLR', 0, '00'].map(num => (
					<button className='number-button' onClick={handleNumberClick} value={num} key={num}>
						{num}
					</button>
				))}
			</div>
			<button className='submit-assemble' onClick={handleAssembleSubmit}>
				Assemble
			</button>
			<button className='submit-disassemble' onClick={handleDisassembleSubmit}>
				Disassemble
			</button>
		</div>
	)
}

const AssemblyLog = ({ data }) => {
	// Row Data: The data to be displayed.
	const rowData = data
	const [gridApi, setGridApi] = useState(null)

	const onGridReady = useCallback(params => {
		setGridApi(params.api)
	}, [])

	// Apply settings across all columns
	const defaultColDef = useMemo(() => ({
		filter: true,
	}))

	const colorValuesRenderer = ({ value }) => {
		const style = parseInt(value) < 1 ? { color: 'red' } : { color: 'green' }
		return <span style={style}>{value}</span>
	}

	// Column Definitions: Defines the columns to be displayed.
	const [colDefs, setColDefs] = useState([
		{ headerName: 'Location', field: 'location_name' },
		{ headerName: 'Bom ID', field: 'bom_id' },
		{ headerName: 'User', field: 'user_name' },
		{
			headerName: 'QTY',
			field: 'quantity',
			cellRenderer: colorValuesRenderer,
		},
		{
			headerName: 'Date',
			field: 'date_assembled',
			cellRenderer: ({ value }) => formatDate(value),
		},
	])

	// Sizing settings for the grid
	const autoSizeStrategy = {
		type: 'fitGridWidth',
		defaultMinWidth: 100,
		columnLimits: [{ colId: 'date_assembled', minWidth: 300 }],
	}

	return (
		<div className='ag-theme-quartz' style={{ height: 400 }}>
			<AgGridReact
				rowData={rowData}
				columnDefs={colDefs}
				pagination={true}
				paginationAutoPageSize={true}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				autoSizeStrategy={autoSizeStrategy}
			/>
		</div>
	)
}

export default QuickAssemble
