import './Dashboard.css'
import { useLocation, useNavigate, NavLink, Outlet } from 'react-router-dom'
import logo from '../../images/etree-logo-transparent.png'
import logoWhite from '../../images/etree-logo-white.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faExternalLink, faGauge, faLocation, faParachuteBox, faPuzzlePiece, faSignOut, faUser, faUserTie, faWrench } from '@fortawesome/free-solid-svg-icons'
import { useContext, useEffect, useState } from 'react'
import UserContext from '../Context/UserContext'

function Dashboard(props) {
	const location = useLocation()
	const navigate = useNavigate()

	const mobileWidthPx = 800

	const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidthPx)
	const [showSidebar, setShowSidebar] = useState(true)

	// update isMobile based on screen size
	const updateMedia = () => {
		if (window.innerWidth < mobileWidthPx) {
			setIsMobile(true)
			setShowSidebar(false)
			return
		}
		setIsMobile(false)
		setShowSidebar(true)
	}
	useEffect(() => {
		window.addEventListener('resize', updateMedia)
		return () => window.removeEventListener('resize', updateMedia)
	}, [])
	useEffect(() => {
		if (isMobile) setShowSidebar(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])
	const toggleSideBar = () => {
		setShowSidebar(!showSidebar)
	}

	return (
		<div id='Dashboard'>
			{showSidebar && isMobile && <div id='close-sidebar' onClick={toggleSideBar}></div>}
			<div id='main-sidebar-wrap' className={(isMobile ? 'mobile' : '') + (showSidebar ? '' : ' hidden')}>
				<MainSidebar />
			</div>
			<div id='page-wrap'>
				{isMobile && (
					<div id='mobile-header'>
						<div className='left'>
							<span className='sidebar-toggle spanButton' onClick={toggleSideBar} role='button' aria-label='Open Navigation Menu'>
								<FontAwesomeIcon icon={faBars} />
							</span>
						</div>
						<div className='logo'>
							<img alt='CONFLUX' src={logoWhite} />
						</div>
						<div className='right'>
							<span className='spanButton' onClick={() => navigate('/dashboard/logout')} role='button' aria-label='Log out'>
								<FontAwesomeIcon icon={faSignOut} />
							</span>
						</div>
					</div>
				)}
				<Page>
					<Outlet />
				</Page>
			</div>
		</div>
	)
}

function MainSidebar() {
	const { user } = useContext(UserContext)
	console.log(user)

	return (
		<aside id='MainSidebar'>
			<div id='app-name'>
				<span>CONFLUX</span>
			</div>
			<div id='main-logo'>
				<img alt='ETREE PRODUCTIONS LTD' src={logo} />
			</div>
			<div className='user-greeting'>
				<p>Welcome, {user.name}</p>
			</div>
			<MainSidebarMenu />
		</aside>
	)
}

function MainSidebarMenu() {
	return (
		<nav id='MainSidebarMenu'>
			<ul>
				<MainSidebarMenuItem faIcon={faGauge} title='Dashboard' link='/dashboard' />
				<MainSidebarMenuItem faIcon={faPuzzlePiece} title='Materials' link='/dashboard/materials' />
				<MainSidebarMenuItem faIcon={faUserTie} title='Customers' link='/dashboard/customers' />
				<MainSidebarMenuItem faIcon={faParachuteBox} title='Vendors' link='/dashboard/vendors' />
				<MainSidebarMenuItem faIcon={faUser} title='Users' link='/dashboard/users' />
				<MainSidebarMenuItem faIcon={faLocation} title='Locations' link='/dashboard/locations' />
				<MainSidebarMenuItem faIcon={faExternalLink} title='Log out' link='/dashboard/logout' />
			</ul>
		</nav>
	)
}

function MainSidebarMenuItem({ title, link, faIcon }) {
	return (
		<li className='MainSidebarMenuItem'>
			<NavLink to={link}>
				{faIcon && <FontAwesomeIcon icon={faIcon} />}
				<span>{title}</span>
			</NavLink>
		</li>
	)
}

function Page(props) {
	return <div id='Page'>{props.children}</div>
}

export default Dashboard
