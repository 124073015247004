import { useNavigate, useParams, Link } from 'react-router-dom'
import PageContent from '../../layout/PageContent'
import PageTitle from '../../layout/PageTitle'
import './Material.css'
import useFetch from '../../../hooks/useFetch'
import ErrorMessage from '../../layout/ErrorMessage/ErrorMessage'
import LoadingMessage from '../../layout/LoadingMessage/LoadingMessage'
import Inventory from './Inventory/Inventory'
import Boms from './Boms/Boms'
import Collapsible from '../../layout/Collapsible/Collapsible'
import Assemble from './Assemble/Assemble'
import UploadImage from '../../UploadImage/UploadImage'
import { useState } from 'react'
import placeholderImage from '../../../images/placeholder-square.jpg'
import axios from 'axios'
import ButtonBar from '../../layout/ButtonBar/ButtonBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import Checkbox from '../../layout/form_components/Checkbox/Checkbox'

const Material = () => {
	const params = useParams()
	const materialId = params.materialId

	const { error, isPending, data: material, reloadData: reloadMaterial } = useFetch('/api/material?id=' + materialId)

	return (
		<div id='Material'>
			<PageTitle>Material - {material?.name || materialId}</PageTitle>
			<PageContent>
				{error && <ErrorMessage message={error} />}
				{isPending && <LoadingMessage />}
				{material && <MaterialInfo material={material} reloadMaterial={reloadMaterial} />}
			</PageContent>
		</div>
	)
}

const MaterialInfo = ({ material, reloadMaterial }) => {
	const navigate = useNavigate()

	const valueChecker = {
		imageLocation: material?.image_location,
		name: material.name,
		sku: material.sku,
		description: material.description,
		costPrice: parseFloat(material.cost_price || 0),
		salesPrice: parseFloat(material.sales_price || 0),
		isVendable: material.is_vendable,
	}

	const [materialValues, setMaterialValues] = useState({...valueChecker})

	const changesMade = !(JSON.stringify(valueChecker) === JSON.stringify(materialValues))

	const setImageLocation = location => setMaterialValues({ ...materialValues, imageLocation: location })
	const handInputChange = e => setMaterialValues({ ...materialValues, [e.target.name]: e.target.value })
	const handCheckboxChange = e => setMaterialValues({ ...materialValues, [e.target.name]: e.target.checked })

	const handleSubmit = async e => {
		e.preventDefault()
		axios
			.put('/api/material?id=' + material.material_id, materialValues)
			.then(res => {
				if (res.data?.success) reloadMaterial()
			})
			.catch(err => {
				console.log(err)
				alert(err.response.data?.error || err.message)
			})
	}

	const hostLocation = 'https://storage.googleapis.com/conflux-uploads/'
	const materialImage = materialValues.imageLocation ? hostLocation + materialValues.imageLocation : placeholderImage

	return (
		<div id='Material'>
			<ButtonBar>
				{material.default_bom_id && (
					<button className='Button' onClick={() => navigate(`/dashboard/quick-assemble/${material.material_id}?bomId=${material.default_bom_id}`)}>
						Open in Quick Assemble
					</button>
				)}
			</ButtonBar>
			<Collapsible title='Material Information'>
				<div>
					<h3>Material Image</h3>
					<img style={{ objectFit: 'contain', margin: '5px' }} height={100} width={200} alt={materialValues.imageLocation} src={materialImage} />
					<br/>
					{materialValues.imageLocation && (
						<button className='Button negative' onClick={() => setImageLocation('')}>
							Remove Image
						</button>
					)}
					<UploadImage setImageLocation={setImageLocation} />
				</div>
				<form onSubmit={handleSubmit}>
					<label>
						Material ID: {material.material_id}{' '}
						<small>
							<i>- (System generated ID - this cannot be changed.)</i>
						</small>
						<input type='text' value={material.material_id} disabled={true} />
					</label>
					<label>Material Name
						<input type='text' name='name' value={materialValues.name} onChange={handInputChange} />
					</label>
					<label>Material SKU
						<input type='text' name='sku' value={materialValues.sku} onChange={handInputChange} />
					</label>
					<label>Material Description
						<input type='text' name='description' value={materialValues.description} onChange={handInputChange} />
					</label>
					<label>Cost Price
						<input className="df full-width" type='number' min="0" step="any" name='costPrice' value={materialValues.costPrice} onChange={handInputChange} />
					</label>
					<label>Is Vendable?
						<Checkbox name='isVendable' checked={materialValues.isVendable} onChange={handCheckboxChange} />
					</label>
					{materialValues.isVendable &&
						<label>Sales Price
							<input className="df full-width" type='number' min="0" step="any" name='salesPrice' value={materialValues.salesPrice} onChange={handInputChange} />
						</label>
					}
					<input type='submit' value='Save changes' disabled={!changesMade} />
				</form>
			</Collapsible>
			<Collapsible title='Inventory'>
				<Inventory materialId={material.material_id} />
			</Collapsible>
			<div className='collapsible-column-view'>
				<Collapsible title={'Parent Materials'}>
					<MaterialParents materialId={material.material_id} />
				</Collapsible>
				<Collapsible title={'Child Materials'}>
					<MaterialChildren materialId={material.material_id} />
				</Collapsible>
			</div>
			<Collapsible title='Bill of Materials' defaultClosed={true}>
				<Boms material={material} reloadMaterial={reloadMaterial} />
			</Collapsible>
			<Collapsible title='Assemble' defaultClosed={true}>
				<Assemble materialId={material.material_id} bomId={material.default_bom_id} material={material} />
			</Collapsible>
		</div>
	)
}

const MaterialParents = ({ materialId }) => {
	const { data, isPending, error } = useFetch('/api/material/' + materialId + '/parents')
	if (isPending) return <LoadingMessage />
	if (error) return <ErrorMessage message={error} />
	if (data.length === 0) return <p>This material has no parents.</p>
	return (
		<div>
			<p>This material is featured in a BOM for the following:</p>
			<SimpleMaterialList materials={data} />
		</div>
	)
}

const MaterialChildren = ({ materialId }) => {
	const { data, isPending, error } = useFetch('/api/material/' + materialId + '/children')
	if (isPending) return <LoadingMessage />
	if (error) return <ErrorMessage message={error} />
	if (data.length === 0) return <p>This material has no children.</p>
	return (
		<div>
			<p>This material has BOMs that include:</p>
			<SimpleMaterialList materials={data} />
		</div>
	)
}

const SimpleMaterialList = ({ materials }) => {
	return (
		<ul>
			{materials.map(material => (
				<SimpleMaterialListItem key={material.material_id} material={material} />
			))}
		</ul>
	)
}

const SimpleMaterialListItem = ({ material }) => {
	return (
		<li>
			[{material.sku}] {material.name} <Link to={'/dashboard/material/' + material.material_id}><FontAwesomeIcon icon={faUpRightFromSquare} /></Link>
		</li>
	)
}

export default Material
