import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import './LoginPage.css'
import { Navigate, useNavigate } from 'react-router-dom'
import ErrorMessage from '../layout/ErrorMessage/ErrorMessage'
import UserContext from '../Context/UserContext'
import LoadingMessage from '../layout/LoadingMessage/LoadingMessage'
import logoWhite from '../../images/etree-logo-white.png'

function LoginPage() {
	const { user } = useContext(UserContext)

	if (user.loaded && user.ok) return <Navigate to='/dashboard' />

	return (
		<div id='LoginPage'>
			<div id='login-page-content'>
				<div className='logo'>
					<img alt='CONFLUX' src={logoWhite} />
				</div>
				<h1>Conflux System</h1>
				<p>
					This is an internal system for employees of <br />
					<b>Etree Productions Limited</b>
				</p>
				<LoginForm />
			</div>
		</div>
	)
}

const LoginForm = props => {
	const { user, loadUser } = useContext(UserContext)

	const [inputs, setInputs] = useState({})
	const [errorMessage, setErrorMessage] = useState(null)

	const handleChange = event => {
		const name = event.target.name
		const value = event.target.value
		setInputs(values => ({ ...values, [name]: value }))
	}

	const handleSubmit = async event => {
		event.preventDefault()
		axios
			.post('/api/auth/login', inputs)
			.then(res => {
				loadUser()
			})
			.catch(err => {
				console.log(err)
				setErrorMessage(err.response.data?.message || err.message)
			})
	}
	return (
		<div id='LoginForm'>
			{errorMessage && <ErrorMessage message={errorMessage} />}
			<form onSubmit={handleSubmit}>
				{!user.loaded && <LoadingMessage message={"Checking auth..."}/>}
				<label htmlFor='email'>Email</label>
				<input name='email' type='email' value={inputs.email || ''} onChange={handleChange} />
				<label htmlFor='password'>Password</label>
				<input name='password' type='password' value={inputs.password || ''} onChange={handleChange} />
				<input type='submit' value='Log in' />
			</form>
		</div>
	)
}

export default LoginPage
