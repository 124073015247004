import { useState } from "react"
import "./Collapsible.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faArrowUp,
	faChevronDown,
	faChevronUp,
} from "@fortawesome/free-solid-svg-icons"

const Collapsible = ({ title, children, defaultClosed = false }) => {
	const [open, setOpen] = useState(!defaultClosed)

	const toggle = () => {
		setOpen(!open)
	}

	return (
		<div className='Collapsible'>
			<div className='title'>
				<span>{title}</span>
				<button onClick={toggle}>
					<FontAwesomeIcon
						icon={open ? faChevronUp : faChevronDown}
					/>
				</button>
			</div>
			<div className={"content " + (open ? " open" : " closed")}>
				<div className='content-inner'>{children}</div>
			</div>
		</div>
	)
}

export default Collapsible
