import './Checkbox.css'

const Checkbox = ({name, checked, onChange}) => {
    return (
        <div class="checkbox-wrapper">
            <label class="switch">
                <input type="checkbox" name={name} checked={checked} onChange={onChange}/>
                <div class="slider round"></div>
            </label>
        </div>
    )
}

export default Checkbox
