import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import LoadingMessage from "../../layout/LoadingMessage/LoadingMessage"
import axios from "axios"
import UserContext from "../../Context/UserContext"

const Logout = () => {
	const navigate = useNavigate()

	const { loadUser } = useContext(UserContext)

	useEffect(() => {
		const logout = () => {
			axios
				.delete("/api/auth/logout")
				.then(res => {
					localStorage.clear()
					loadUser()
				})
				.catch(err => {
					console.log(err)
					alert(err.message)
				})
		}
		logout()
	}, [navigate])

	return <LoadingMessage />
}

export default Logout
