import "./ErrorMessage.css"

const ErrorMessage = ({ message }) => {
	const messageText = message
		? message
		: "An error occurred. Please check logs or contact support."

	return (
		<div className='ErrorMessage'>
			<span className='err-prefix'>ERROR</span>
			<span className='err-message'>{messageText}</span>
		</div>
	)
}

export default ErrorMessage
